import { graphql, useStaticQuery } from "gatsby";

const ALL_IMAGES = graphql`
  query AllImages2 {
    images: allFile(filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }) {
      edges {
        node {
          dir
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

function useImages(names: string[]) {
  const data = useStaticQuery<any>(ALL_IMAGES);
  const result = data!
    .images!.edges!.filter((img: any) => names.includes(img.node.name))
    .map(img => ({
      fluid: img.node.childImageSharp!.fluid,
      name: img.node.name,
    }));
  return result;
}

export default useImages;
