import React, { FC } from "react";
import Layout from "../components/Layout";
import { Routes } from "../constants/routes";
import SEO from "../components/Seo";
import useImages from "../hooks/useImages";
import styled from "styled-components";
import Image from "gatsby-image";

const Container = styled.div`
  padding: var(--s12);
  @media (max-width: 700px) {
    padding: var(--s4);
  }
`;

const Foto = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: var(--s16);
  img {
    height: 100vh;
  }
  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 2rem;
    line-height: 3.3rem;
    font-weight: 300;
    @media (max-width: 700px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  &:not(:first-of-type) {
    margin-top: var(--s64);
  }
  p {
    align-self: end;
    @media screen and (max-width: 700px) {
      order: 1;
      margin-top: var(--s4);
    }
  }
  &:nth-of-type(odd) {
    grid-template-columns: 3fr 2fr;
    p {
      order: 1;
      text-align: right;
      @media screen and (max-width: 700px) {
        text-align: left;
        order: 1;
      }
    }
    img {
      order: -1;
    }
  }
  &:last-of-type {
    margin-bottom: 5rem;
  }
`;

const FotosPage: FC = () => {
  const imagesRaw = useImages([1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => `foto${n}`));
  const images = imagesRaw.sort((a, b) => a.name.localeCompare(b.name));
  const captions = [
    "Einsiedlerstrasse 9, Wädenswil, 1987 – 1999",
    "Lehrerseminar in Küsnacht und Zürich, 1940 – 1945",
    "Bruno Heller erklärt seiner Tochter Barbara die Kunst des Bildaufbaus, 2014",
    "Atelier «Loft», Oberdorfstrasse, Wädenswil, 1999 – 2014",
    "Atelier an der Einsiedlerstrasse, 1987 – 1999",
    "Heidi und Bruno Heller, im Restaurant Volkshaus Wädenswil, 1993",
    "Einsiedlerstrasse 9, Wädenswil, 1987 – 1999",
    "Einsiedlerstrasse 9, Wädenswil, 1987 – 1999",
    "Heidi und Bruno Heller, Langrüti, Wädenswilerberg, 1960 – 1987",
  ];
  return (
    <Layout activePage={Routes.FOTOS}>
      <SEO title="Ausstellungen" />
      <Container>
        {captions.map((c, i) => (
          <Foto key={i}>
            <p>{c}</p>
            <Image
              style={{ maxHeight: "90vh" }}
              imgStyle={{
                objectFit: "contain",
                objectPosition: i % 2 === 0 ? "left" : "right",
              }}
              alt={c}
              fluid={images[i].fluid}
            />
          </Foto>
        ))}
      </Container>
    </Layout>
  );
};

export default FotosPage;
